<template>
	<Row class="sykes-on-youtube">
		<Container>
			<Heading
				tag="h2"
				type="big"
				class="border-l-[8px] pl-[16px] text-white border-primary-500 leading-none mb-[24px]"
				text="Timothy Sykes on Youtube"
			/>

			<div class="stats-container mb-[24px] lg:mb-[48px]">
				<div class="stat-item">
					<svg
						width="40"
						height="41"
						viewBox="0 0 40 41"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect
							y="0.5"
							width="40"
							height="40"
							rx="20"
							fill="#00B67A"
						/>
						<path
							d="M31.7223 11.4755C31.4914 11.4824 31.2722 11.5789 31.1112 11.7446L27.5173 16.7573L25.4791 14.7191C25.3124 14.5525 25.0864 14.4589 24.8507 14.4589C24.615 14.4589 24.3889 14.5525 24.2222 14.7191L21.2951 17.6462L19.2569 17.0175C19.0902 16.8509 18.6285 16.7573 18.6285 16.7573C18.6285 16.7573 18.1667 16.8509 18 17.0175L14.2732 23.5127C14.1879 23.5947 14.1198 23.6928 14.0729 23.8013C14.026 23.9099 14.0012 24.0267 14 24.145C13.9988 24.2632 14.0212 24.3805 14.0659 24.49C14.1106 24.5995 14.1767 24.699 14.2603 24.7826C14.344 24.8662 14.4434 24.9323 14.5529 24.977C14.6624 25.0217 14.7797 25.0441 14.898 25.0429C15.0162 25.0417 15.1331 25.0169 15.2416 24.97C15.3502 24.9231 15.4483 24.855 15.5302 24.7697L19 19.0429L20.6667 19.5316C20.8334 19.6982 21.0594 19.7918 21.2951 19.7918C21.5308 19.7918 21.7569 19.6982 21.9236 19.5316L24.8507 16.6045L26.8888 18.6427C27.0555 18.8093 27.2816 18.903 27.5173 18.903C27.753 18.903 27.9791 18.8093 28.1458 18.6427L32.3681 13.0015C32.4966 12.8767 32.5843 12.7159 32.6198 12.5403C32.6553 12.3648 32.637 12.1826 32.5672 12.0176C32.4974 11.8526 32.3793 11.7126 32.2286 11.6159C32.0778 11.5191 31.9013 11.4702 31.7223 11.4755ZM8.90154 27.7474C8.78376 27.7458 8.66681 27.7675 8.55751 27.8115C8.4482 27.8554 8.34872 27.9206 8.26483 28.0033C8.18095 28.086 8.11434 28.1846 8.06888 28.2932C8.02341 28.4019 8 28.5185 8 28.6363C8 28.7541 8.02341 28.8708 8.06888 28.9794C8.11434 29.0881 8.18095 29.1867 8.26483 29.2694C8.34872 29.3521 8.4482 29.4173 8.55751 29.4612C8.66681 29.5051 8.78376 29.5269 8.90154 29.5252L31.7183 29.4813C31.8361 29.483 31.953 29.4612 32.0623 29.4173C32.1716 29.3734 32.2711 29.3081 32.355 29.2254C32.4389 29.1427 32.5055 29.0442 32.5509 28.9355C32.5964 28.8268 32.6198 28.7102 32.6198 28.5924C32.6198 28.4746 32.5964 28.358 32.5509 28.2493C32.5055 28.1406 32.4389 28.0421 32.355 27.9594C32.2711 27.8767 32.1716 27.8115 32.0623 27.7675C31.953 27.7236 31.8361 27.7019 31.7183 27.7035L8.90154 27.7474Z"
							fill="white"
						/>
						<path
							d="M10 10.5C9.46957 10.5 8.96086 10.7107 8.58579 11.0858C8.21071 11.4609 8 11.9696 8 12.5C8 13.0304 8.21071 13.5391 8.58579 13.9142C8.96086 14.2893 9.46957 14.5 10 14.5C10.5304 14.5 11.0391 14.2893 11.4142 13.9142C11.7893 13.5391 12 13.0304 12 12.5C12 11.9696 11.7893 11.4609 11.4142 11.0858C11.0391 10.7107 10.5304 10.5 10 10.5ZM15.987 14.4948C15.8593 14.4989 15.7354 14.5395 15.6302 14.612L13.7982 15.8333H13.3333H9.33333C8.59667 15.8333 8 16.43 8 17.1667V25.8112C8 26.1912 8.3088 26.5 8.6888 26.5C9.05147 26.5 9.3523 26.2181 9.3763 25.8568L9.66797 21.4779C9.67997 21.3032 9.82467 21.1667 10 21.1667C10.1753 21.1667 10.32 21.3026 10.332 21.4766L10.6237 25.8568C10.6477 26.2188 10.9485 26.5 11.3112 26.5C11.6912 26.5 12 26.1912 12 25.8112V17.8333C12 17.4653 12.2987 17.1667 12.6667 17.1667H14C14.1316 17.1667 14.2603 17.1277 14.3698 17.0547L16.3698 15.7214C16.4923 15.6423 16.5854 15.5253 16.6351 15.3883C16.6847 15.2512 16.6881 15.1017 16.6447 14.9625C16.6012 14.8234 16.5134 14.7023 16.3946 14.6179C16.2759 14.5334 16.1327 14.4902 15.987 14.4948Z"
							fill="white"
						/>
					</svg>

					<span class="highlight">3+</span>

					New Lessons Weekly
				</div>
				<div class="stat-item">
					<span
						class="icon"
						style="background-color: #3b82f6"
					>
						<svg
							width="26"
							height="17"
							viewBox="0 0 26 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								id="Vector"
								d="M13 0.5C5.56809 0.5 0.76749 7.86894 0.680664 8.00313C0.563794 8.14445 0.500126 8.31955 0.5 8.5C0.500148 8.6548 0.547075 8.80623 0.635091 8.93594C0.63617 8.9375 0.637256 8.93907 0.638346 8.94063C0.652674 8.96901 4.68236 16.5 13 16.5C21.2824 16.5 25.3061 9.04299 25.3551 8.95156C25.3585 8.94639 25.3617 8.94118 25.3649 8.93594C25.4529 8.80623 25.4999 8.6548 25.5 8.5C25.5 8.32028 25.4369 8.1458 25.321 8.00469C25.3204 8.00417 25.3199 8.00365 25.3193 8.00313C25.2325 7.86894 20.4319 0.5 13 0.5ZM13 2.9C16.2217 2.9 18.8333 5.4072 18.8333 8.5C18.8333 11.5928 16.2217 14.1 13 14.1C9.77833 14.1 7.16667 11.5928 7.16667 8.5C7.16667 5.4072 9.77833 2.9 13 2.9ZM13 6.1C12.337 6.1 11.7011 6.35286 11.2322 6.80294C10.7634 7.25303 10.5 7.86348 10.5 8.5C10.5 9.13652 10.7634 9.74697 11.2322 10.1971C11.7011 10.6471 12.337 10.9 13 10.9C13.663 10.9 14.2989 10.6471 14.7678 10.1971C15.2366 9.74697 15.5 9.13652 15.5 8.5C15.5 7.86348 15.2366 7.25303 14.7678 6.80294C14.2989 6.35286 13.663 6.1 13 6.1Z"
								fill="white"
							/>
						</svg>
					</span>

					<span class="highlight">37M+</span>

					views
				</div>
				<div class="stat-item">
					<span
						class="icon"
						style="background-color: #db4c3f"
					>
						<svg
							width="22"
							height="17"
							viewBox="0 0 22 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g id="Group 55">
								<path
									id="Vector"
									d="M21.2133 13.78C21 14.9 20.0933 15.7533 18.9733 15.9133C17.2133 16.18 14.28 16.5 10.9733 16.5C7.71997 16.5 4.78664 16.18 2.97331 15.9133C1.85331 15.7533 0.946641 14.9 0.733307 13.78C0.519974 12.5533 0.306641 10.74 0.306641 8.5C0.306641 6.26 0.519974 4.44667 0.733307 3.22C0.946641 2.1 1.85331 1.24667 2.97331 1.08667C4.73331 0.82 7.66664 0.5 10.9733 0.5C14.28 0.5 17.16 0.82 18.9733 1.08667C20.0933 1.24667 21 2.1 21.2133 3.22C21.4266 4.44667 21.6933 6.26 21.6933 8.5C21.64 10.74 21.4266 12.5533 21.2133 13.78Z"
									fill="white"
								/>
								<path
									id="Vector_2"
									d="M8.83997 12.2333V4.7666L15.24 8.49994L8.83997 12.2333Z"
									fill="#DB4C3F"
								/>
							</g>
						</svg>
					</span>

					<span class="highlight">1.8K</span>

					videos
				</div>
				<div class="stat-item">
					<span
						class="icon"
						style="background-color: #eab308"
					>
						<svg
							width="14"
							height="17"
							viewBox="0 0 14 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								id="Vector"
								d="M5.79998 0.5V2.25625C3.73052 2.78968 2.19998 4.66384 2.19998 6.9V11.7L0.599976 13.3V14.1H13.4V13.3L11.8 11.7V6.9C11.8 4.66384 10.2694 2.78968 8.19998 2.25625V0.5H5.79998ZM5.39998 14.9C5.39998 15.78 6.11998 16.5 6.99998 16.5C7.87998 16.5 8.59998 15.78 8.59998 14.9H5.39998Z"
								fill="white"
							/>
						</svg>
					</span>

					<span class="highlight">461K</span>

					subscribers
				</div>
			</div>

			<div class="youtube-thumbnails mb-[24px] lg:mb-[48px]">
				<NuxtLink
					class="youtube-item"
					to="https://youtu.be/fgA0V9vpcQ0?si=ekCpB1pj4SBZ45xj"
					target="_blank"
				>
					<img
						src="/images/assets/yt-image-1.webp"
						width="794"
						height="452"
						alt="yt-image-1"
					/>
				</NuxtLink>
				<NuxtLink
					class="youtube-item"
					to="https://youtu.be/AfHMQkzVoio?si=IJysnTo0hNKAzK58"
					target="_blank"
				>
					<img
						src="/images/assets/AfHMQkzVoio.webp"
						width="390"
						height="220"
						alt="yt-image-2"
					/>
				</NuxtLink>
				<NuxtLink
					class="youtube-item"
					to="https://youtu.be/C5Luw6K1p1k?si=UJXORsV4fGsahWJT"
					target="_blank"
				>
					<img
						src="/images/assets/C5Luw6K1p1k.webp"
						width="390"
						height="220"
						alt="yt-image-3"
					/>
				</NuxtLink>
				<NuxtLink
					class="youtube-item"
					to="https://youtu.be/D2aF4O31BSA?si=bDZjzElgGJ80A2tc"
					target="_blank"
				>
					<img
						src="/images/assets/D2aF4O31BSA.webp"
						width="390"
						height="220"
						alt="yt-image-4"
					/>
				</NuxtLink>
				<NuxtLink
					class="youtube-item"
					to="https://youtu.be/q6qp0LRRyCc?si=GGAPCznyDT8FCLUE"
					target="_blank"
				>
					<img
						src="/images/assets/q6qp0LRRyCc.webp"
						width="390"
						height="220"
						alt="yt-image-5"
					/>
				</NuxtLink>
				<NuxtLink
					class="youtube-item"
					to="https://youtu.be/xDbcStOuEbw?si=qte36-s77DyKp2Va"
					target="_blank"
				>
					<img
						src="/images/assets/xDbcStOuEbw.webp"
						width="390"
						height="220"
						alt="yt-image-6"
					/>
				</NuxtLink>
			</div>

			<CtaButton
				to="https://www.youtube.com/@TimothySykesTrader"
				class="text-center"
				:blank="true"
				type="secondary"
			>
				Subscribe to Timothy Sykes’ Channel
			</CtaButton>
		</Container>
	</Row>
</template>

<style lang="scss" scoped>
@import "../assets/utils.scss";

.sykes-on-youtube {
	background-color: #0a0e1d;
}

.youtube-thumbnails {
	display: flex;
	justify-content: center;
	gap: 32px;
	align-items: center;
	flex-wrap: wrap;

	@include breakpoint(lg) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(3, 1fr);
		gap: 16px;
	}

	a {
		width: 100%;
		img {
			width: 100%;
		}
	}
}

.youtube-item:first-child {
	grid-column: span 2;
	grid-row: span 2;
}

.stats-container {
	display: flex;
	justify-content: flex-start;
	gap: 32px;
	flex-wrap: wrap;
}

.stat-item {
	display: flex;
	align-items: flex-end;
	gap: 8px;
	color: #fff;

	/* Body/Xlarge */
	font-family: Rubik;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	white-space: nowrap;
}

.stat-item .icon {
	border-radius: 32px;
	justify-content: center;
	align-items: center;
	display: flex;
	height: 40px;
	width: 40px;
}

.stat-item .highlight {
	font-family: Fira Sans;
	font-size: 38px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	/* 45.6px */
	letter-spacing: -0.76px;
}
</style>
